import React from "react";
import {RouteComponentProps} from "react-router-dom";
import useUser from "../api/hooks/useUser";
import UserProfile from "../components/users/UserProfile";
import {Box} from "@mui/material";
import UserAnalysis from "../components/users/UserAnalysis";
import AddAnalysisButton from "../components/analysis/AddAnalysisButton";

interface Props extends RouteComponentProps<{ id: string }> {
  id: string;
}

const UserPage: React.FC<Props> = ({ match }) => {
  const userId = match.params.id;
  const user = useUser(userId);
  return (
    <Box display="flex" flexDirection="column">

      {user && <UserProfile user={user} />}
      <Box mx={10} display="flex" flexDirection="column">
        <AddAnalysisButton userId={userId} />
        <UserAnalysis userId={userId} />
      </Box>
    </Box>
  );
};

export default UserPage;
