import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
} from "@mui/material";
import formatDateShort from "../utils/formatDateShort";
import QRCode from "react-qr-code";
import logo from "../assets/logo.png";
import { withStyles } from "@mui/styles";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useSingleAnalysis from "../api/hooks/useSingleAnalysis";
import useUser from "../api/hooks/useUser";
import formatDateLong from "../utils/formatDateLong";
import { Gender } from "../types/User";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {capitalize} from "lodash/fp";
import capitalizeSafe from "../utils/capitalizeSafe";

const TableHead = withStyles(() => ({
  root: {
    backgroundColor: "#ccc",
  },
}))(MuiTableHead);

interface Props
  extends RouteComponentProps<{ id: string; analysisId: string }> {}

export const genderLabel = (gender?: Gender) => {
  if (!gender) return "";
  switch (gender) {
    case Gender.FEMALE:
      return "Zenski";
    case Gender.MALE:
      return "Muski";
  }
};
const AnalysisPage: React.FC<Props> = ({ match }) => {
  const analysis = useSingleAnalysis(match.params.id, match.params.analysisId);
  const user = useUser(match.params.id);
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box style={{ alignSelf: "flex-start" }} displayPrint="none">
        <Button onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
          {capitalizeSafe(user?.firstname)} {capitalizeSafe(user?.lastname)}
        </Button>
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "210mm",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography>Biohem-Mikro</Typography>
            <Typography>Mikrobioloska laboratorija</Typography>
            <Typography>Kosovska 25</Typography>
            <Typography>17520, Bujanovac</Typography>
            <Typography>biohem.mikro@gmail.com</Typography>
            <Typography>PIB: 112749786</Typography>
            <Typography>+381 (0)63 7322 623</Typography>
          </Box>
          <img src={logo} width="50%" alt="logo" />
        </Box>
        <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <UserData
              label="Ime i prezime"
              englishLabel="Name"
              value={`${capitalizeSafe(user?.firstname)} ${capitalizeSafe(user?.lastname)}`}
            />
            <UserData
              label="Datum rodjenja"
              englishLabel="Date of birth"
              value={formatDateShort(user?.dateOfBirth)}
            />
            <UserData
              label="JMBG"
              value={user?.jmbg}
              englishLabel="Personal number"
            />
          </Box>
          <Box>
            <UserData
              label="Pol"
              value={genderLabel(user?.gender)}
              englishLabel="Gender"
            />
            <UserData
              label="E-mail"
              value={user?.email}
              englishLabel="E-mail"
            />
          </Box>
        </Box>
        <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <UserData
            label="Datum izdavanja"
            value={formatDateLong(analysis?.dateOfIssue)}
            englishLabel="Date of issue"
            noMargin
          />
        </Box>
        <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
        <Typography variant="h4" gutterBottom>
          Izveštaj o ispitivanju / Test report
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Analiza/Analysis</TableCell>
                <TableCell align="center">Rezultat/Result</TableCell>
                <TableCell align="center">Ref. Vrednosti/Ref. Value</TableCell>
                <TableCell align="center">Metoda/Method</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={"row.name"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{analysis?.type}*</TableCell>
                <TableCell align="center">{analysis?.result}</TableCell>
                <TableCell align="center">
                  {analysis?.referenceValues}
                </TableCell>
                <TableCell align="center">
                  Lumiquick Diagnostics Inc.
                  <br /> Usa - Immunochromatographic
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="caption" gutterBottom>
          * Ukoliko je vas rezultat POZITIVAN ili NEGATIVAN, a IMATE SIMPTOME,
          molimo vas da se javite u nadleznu COVID ambulantu
        </Typography>
        <Box my={2}>
          <Typography align="left" gutterBottom variant="body2">
            Rezultat tumačiti u skladu sa kliničkom slikom pacijenta i
            rezultatima drugih dijagnostičkih procedura.
          </Typography>
          <Typography align="left" gutterBottom variant="body2">
            Dijagnosticka specifičnost testa: 99%. Dijagnostička senzitivnost
            testa: 94%.
          </Typography>
          <Typography align="left" gutterBottom variant="body2">
            Naziv testa:QUICK PROFILE COVID 619 ANTIGEN TEST(LUMIQUICK
            DIAGNOSTICS,Inc,USA)
          </Typography>
          <Typography align="left" gutterBottom variant="body2">
            Broj rešenja Ministarstva zdravlja Republike Srbije
            924-530-072-49/21-09 od 27.10.2021.
          </Typography>
          <Typography align="left" gutterBottom variant="body2">
            U skladu sa Zakonom o zaštiti stanovništva od zaraznih
            bolesti,pozitivan rezultat podleže obaveznom prijavljivanju i biće
            dostavljen nadležnom Zavodu za javno zdravlje.
          </Typography>
        </Box>
        <Box alignSelf="flex-start" pt={2}>
          <QRCode
            value={`https://www.lab.biohem.rs/user/${match.params.id}/analysis/${match.params.analysisId}`}
            size={150}
          />
        </Box>
      </Container>
    </Box>
  );
};

const UserData: React.FC<{
  label: string;
  englishLabel: string;
  value?: string;
  noMargin?: boolean;
}> = ({ label, englishLabel, value, noMargin }) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    mb={noMargin ? 0 : 2}
  >
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography mr={2} fontWeight="bold">
        {label}:
      </Typography>

      <Typography mr={2} fontWeight="bold">
        {englishLabel}:
      </Typography>
    </Box>
    {value && <Typography>{value}</Typography>}
  </Box>
);

export default AnalysisPage;
