import React from "react";
import {Button, Container, Paper, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import Login from "../types/Login";
import {makeStyles} from "@mui/styles";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebaseConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#d32f2f",
    },
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Login>({ defaultValues: { password: "", email: "" } });

  const onLogin = (login: Login) => {
    signInWithEmailAndPassword(auth, login.email, login.password).then(
      (user) => {
        console.log("user", user);
      }
    );
  };
  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90vh",
      }}
    >
      <Paper
        component="form"
        onSubmit={handleSubmit(onLogin)}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "5%",
          height: "30vh",
          width: "30vh",
          justifyContent: "space-evenly",
        }}
      >
        <Controller
          rules={{
            required: { value: true, message: "Ovo polje je obavezno." },
          }}
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.root}
              label="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />

        <Controller
          rules={{
            required: { value: true, message: "Ovo polje je obavezno." },
          }}
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.root}
              label="Password"
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          )}
        />
        <Button type="submit" color="info" variant="contained">
          Login
        </Button>
      </Paper>
    </Container>
  );
};

export default LoginPage;
