import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";

export default function useFirestoreCollection(
  collectionPath: string,
  queryFunc?: QueryConstraint[] | null
) {
  const [snapshot, setSnapshot] = useState<QuerySnapshot | undefined>();

  useEffect(() => {
    if (queryFunc === null) {
      setSnapshot(undefined);
      return;
    }

    try {
      const collectionReference = collection(firestore, collectionPath);
      const q = queryFunc
        ? query(collectionReference, ...queryFunc)
        : collectionReference;

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          console.log("useFirestoreCollection", collectionPath);
          setSnapshot(querySnapshot);
        },
        (error) => {
          console.error(
            "Error in useFirestoreCollection",
            collectionPath,
            error
          );
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error(
        "useFirestoreCollection",
        collectionPath,
        "threw an error",
        error
      );
    }
  }, [collectionPath, queryFunc]);

  return snapshot;
}
