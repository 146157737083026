import React, { useEffect, useState } from "react";
import "./App.css";
import UsersPage from "./pages/UsersPage";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import UserPage from "./pages/UserPage";
import AnalysisPage from "./pages/AnalysisPage";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import LoginPage from "./pages/LoginPage";
import { User } from "firebase/auth";
import { auth } from "./firebaseConfig";
import Navbar from "./components/Navbar";

const theme = createTheme({
  components: {
    /* Styles applied to the `Paper` component. */
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "@media print": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

const anonymousRoutes = (
  <Switch>
    <Route
      exact
      path="/user/:id/analysis/:analysisId"
      component={AnalysisPage}
    />
    <Route component={LoginPage} />
  </Switch>
);

const userRoutes = (
  <Switch>
    <Route exact path="/" component={UsersPage} />
    <Route exact path="/user/:id" component={UserPage} />
    <Route
      exact
      path="/user/:id/analysis/:analysisId"
      component={AnalysisPage}
    />
  </Switch>
);

function App() {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    auth.onAuthStateChanged((fbUser) => {
      if (fbUser) {
        setUser(fbUser);
      } else {
        setUser(undefined);
      }
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {user && <Navbar />}
            {user ? userRoutes : anonymousRoutes}
          </LocalizationProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}
export default App;
