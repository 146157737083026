import {collection, doc, setDoc} from "firebase/firestore";
import {isNil, omitBy} from "lodash/fp";
import {addDocument, firestore, setDocument} from "../firebaseConfig";
import {Analysis} from "../types/Analysis";

const analysisRef = (userId: string) =>
  collection(firestore, `users/${userId}/analysis`);
const analysisDocRef = (userId: string, id: string) =>
  doc(firestore, `users/${userId}/analysis`, id);

export const addAnalysis = (userId: string, analysis: Analysis) =>
  addDocument(analysisRef(userId), omitBy(isNil)(analysis));
export const editAnalysis = (
  userId: string,
  analysisId: string,
  analysis: Partial<Analysis>
) => setDocument(analysisDocRef(userId, analysisId), analysis, { merge: true });
export const deleteAnalysis = (userId: string, id: string) =>
  editAnalysis(userId, id, { deleted: true });
