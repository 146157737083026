import { flow, toUpper, update } from "lodash/fp";
import Entity from "./Entity";
import normalizeFirestoreDate from "../utils/normalizeFirestoreDate";

interface UserEntity extends Entity {
  firstname: string;
  lastname: string;
  jmbg: string;
  dateOfBirth: Date;
  lastAnalysis: Date;
  email: string;
  address: string;
  telephoneNumber: string;
  gender: Gender;
  userId: string;
  paidLastAnalysis: boolean;
  deleted: boolean;
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export type User = Omit<UserEntity, "createdAt" | "id">;
export const normalizeUser = (data: any) =>
  flow(
    update("createdAt", normalizeFirestoreDate),
    update("dateOfBirth", normalizeFirestoreDate),
    update("lastAnalysis", normalizeFirestoreDate),
    update("gender", toUpper)
  )(data);
export default UserEntity;
