import {set} from "lodash/fp";
import AnalysisEntity, {normalizeAnalysis} from "../../types/Analysis";
import useFirestoreDocument from "./useFirestoreDocument";

export default function useSingleAnalysis(userId: string, analysisId: string) {
  const analysisDoc = useFirestoreDocument(`users/${userId}/analysis/${analysisId}`);
  return (
    analysisDoc &&
    (normalizeAnalysis(
      set("id", analysisDoc?.id)(analysisDoc?.data() || {})
    ) as AnalysisEntity)
  );
}
