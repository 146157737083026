import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import useAnalysis from "../../api/hooks/useAnalysis";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteAnalysis,
  editAnalysis as editAnalysisApi,
} from "../../api/analysisAPI";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import AnalysisEntity, { Analysis, Result } from "../../types/Analysis";
import { DateTimePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";

interface Props {
  userId: string;
}
const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#d32f2f",
    },
    marginBottom: "20px",
  },
}));

const UserAnalysis: React.FC<Props> = ({ userId }) => {
  const classes = useStyles();
  const analysis = useAnalysis(userId);
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [editAnalysis, setEditAnalysis] = useState<AnalysisEntity>();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Analysis>();

  const onEditAnalysis = (data: Analysis) => {
    console.log(data);
    editAnalysisApi(userId, editAnalysis?.id || "", data);
    setOpen(false);
    setEditAnalysis(undefined);
  };
  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "grey" }}>
            <TableRow>
              <TableCell>Tip</TableCell>
              <TableCell align="right">Metoda</TableCell>
              <TableCell align="right">Rezultat</TableCell>
              <TableCell align="right">Datum testiranja</TableCell>
              <TableCell align="right">Cena</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {analysis?.map((a) => (
              <TableRow
                hover
                style={{ cursor: "pointer" }}
                key={a.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => history.push(`/user/${userId}/analysis/${a.id}`)}
              >
                <TableCell component="th" scope="row">
                  {a.type}
                </TableCell>
                <TableCell align="right">{a.method}</TableCell>
                <TableCell align="right">{a.result}</TableCell>
                <TableCell align="right">
                  {format(a.dateOfIssue, "dd.MM.yyyy HH:mm:ss")}
                </TableCell>
                <TableCell align="right">{a.price}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    // @ts-ignore
                    onClick={(event) => {
                      event.stopPropagation();
                      reset(a);
                      setEditAnalysis(a);
                      setOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    // @ts-ignore
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteAnalysis(userId, a.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right">
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <form
            id="analysis-form"
            onSubmit={handleSubmit(onEditAnalysis)}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="type"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.root}
                  label="Tip"
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  style={{ marginBottom: 15 }}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="method"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.root}
                  label="Metoda"
                  error={!!errors.method}
                  helperText={errors.method?.message}
                  style={{ marginBottom: 15 }}
                />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="input-result-label">Rezultat</InputLabel>
              <Controller
                name="result"
                control={control}
                rules={{
                  required: { value: true, message: "Ovo polje je obavezno." },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Rezultat"
                    style={{ marginBottom: 15 }}
                  >
                    {Object.keys(Result).map((res) => (
                      <MenuItem value={res} key={res}>
                        {res}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="dateOfIssue"
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  label="Datum izdavanja"
                  renderInput={(params) => (
                    <TextField {...params} style={{ marginBottom: 15 }} />
                  )}
                  inputFormat="dd.MM.yyyy HH:mm:ss"
                />
              )}
            />
            <Controller
                name="price"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        type="number"
                        className={classes.root}
                        label="Cena"
                        style={{ marginBottom: 15 }}
                    />
                )}
            />
          </form>
          <Button
            form="analysis-form"
            color="success"
            variant="contained"
            type="submit"
          >
            Ažuriraj analizu
          </Button>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default UserAnalysis;
