import Entity from "./Entity";
import { flow, update } from "lodash/fp";
import normalizeFirestoreDate from "../utils/normalizeFirestoreDate";

interface AnalysisEntity extends Entity {
  result: Result;
  dateOfIssue: Date;
  type: string;
  method: string;
  referenceValues: string;
  deleted: boolean;
  price?: number;
}

export type Analysis = Omit<AnalysisEntity, "createdAt" | "id">;

export enum Result {
  NEGATIVE = "NEGATIVE",
  POSITIVE = "POSITIVE",
}

export const normalizeAnalysis = (data: any) =>
  flow(
    update("createdAt", normalizeFirestoreDate),
    update("dateOfIssue", normalizeFirestoreDate),
    update("dateOfSampling", normalizeFirestoreDate)
  )(data);

export default AnalysisEntity;
