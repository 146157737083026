import useFirestoreCollection from "./useFirestoreCollection";
import { useMemo } from "react";
import { orderBy } from "firebase/firestore";
import { set } from "lodash/fp";
import AnalysisEntity, { normalizeAnalysis } from "../../types/Analysis";

export default function useAnalysis(userId: string) {
  const analysisDocs = useFirestoreCollection(
    `users/${userId}/analysis`,
    useMemo(() => [orderBy("createdAt", "desc")], [])
  );
  return analysisDocs?.docs.map(
    (u) => normalizeAnalysis(set("id", u.id)(u.data())) as AnalysisEntity
  ) as AnalysisEntity[];
}
