import React from "react";
import UsersList from "../components/users/UsersList";
import AddUserButton from "../components/users/AddUserButton";
import {Box, Chip} from "@mui/material";
import UserSearchButton from "../components/users/UserSearchButton";
import UserSearchProvider, {useUserSearchContext,} from "../providers/UserSearchProvider";
import {omit, startCase} from "lodash/fp";
import formatDateShort from "../utils/formatDateShort";

const UsersPage = () => {
  const [userSearchState, setUserSearchState] = useUserSearchContext();
  return (
    <Box py={5} px={20} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <UserSearchButton />
        <AddUserButton />
      </Box>
      <Box display="flex" style={{ gap: "8px" }} flexWrap="wrap" py={5}>
        {Object.entries(userSearchState).map(([key, value]) => (
          <Chip
            label={`${startCase(key)}: ${
              value instanceof Date ? formatDateShort(value) : value
            }`}
            onDelete={() =>
              setUserSearchState((prevState) => omit(key)(prevState))
            }
          />
        ))}
      </Box>
      <Box mt={5}>
        <UsersList />
      </Box>
    </Box>
  );
};
const UsersPageWrapper = () => (
  <UserSearchProvider>
    <UsersPage />
  </UserSearchProvider>
);
export default UsersPageWrapper;
