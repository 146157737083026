import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  addDoc,
  setDoc,
  CollectionReference,
  DocumentReference,
  getFirestore,
  SetOptions,
} from "firebase/firestore";
import { flow, set } from "lodash/fp";

const firebaseConfig = {
  apiKey: "AIzaSyB2hnOXJF1wmBGhQvzUr05ocbH-kPAP0vg",
  authDomain: "biohem-85d4f.firebaseapp.com",
  projectId: "biohem-85d4f",
  storageBucket: "biohem-85d4f.appspot.com",
  messagingSenderId: "468472853528",
  appId: "1:468472853528:web:b0eef6716e1bde78433f6d",
  measurementId: "G-TQ9JJDJTST",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const database = getDatabase(app);

const auth = getAuth(app);

const addDocument: AddDoc = (reference, data) =>
  addDoc(
    reference,
    flow(
      set("createdAt", new Date()),
      set("createdBy", auth.currentUser?.uid)
    )(data)
  );
const setDocument: SetDoc = (reference, data, options = { merge: true }) =>
  setDoc(
    reference,
    flow(
      set("updatedAt", new Date()),
      set("updatedBy", auth.currentUser?.uid)
    )(data),
    options
  );

type AddDoc = <T>(
  reference: CollectionReference<T>,
  data: any
) => Promise<DocumentReference<T>>;

type SetDoc = <T>(
  reference: DocumentReference<T>,
  data: any,
  options: SetOptions
) => Promise<void>;

export { firestore, auth, addDocument, database, setDocument };
