import { useEffect, useState } from "react";
import { doc, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";

export default function useFirestoreDocument(documentPath: string) {
  const [snapshot, setSnapshot] = useState<DocumentSnapshot | undefined>();

  useEffect(() => {
    if (documentPath === null) {
      setSnapshot(undefined);
      return;
    }

    try {
      const collectionReference = doc(firestore, documentPath);

      const unsubscribe = onSnapshot(
        collectionReference,
        (querySnapshot) => {
          console.log("useFirestoreCollection", documentPath);
          setSnapshot(querySnapshot);
        },
        (error) => {
          console.error("Error in useFirestoreCollection", documentPath, error);
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error(
        "useFirestoreCollection",
        documentPath,
        "threw an error",
        error
      );
    }
  }, [documentPath]);

  return snapshot;
}
