import useFirestoreCollection from "./useFirestoreCollection";
import UserEntity, { normalizeUser } from "../../types/User";
import { set } from "lodash/fp";
import { orderBy, where } from "firebase/firestore";
import { useMemo } from "react";
import { useUserSearchContext } from "../../providers/UserSearchProvider";
import { addDays, subDays } from "date-fns";

const useUsers = () => {
  const [userSearchState] = useUserSearchContext();
  const usersDocs = useFirestoreCollection(
    "users",
    useMemo(
      () => [
        ...Object.entries(userSearchState)
          .map(([key, value]) => {
            if (key === "dateOfBirth" && value instanceof Date) {
              return [
                where(key, ">", subDays(value, 1)),
                where(key, "<", addDays(value, 1)),
                orderBy("dateOfBirth", "desc"),
              ];
            }
            return where(key, "==", value);
          })
          .flat(),
        where('deleted', "==", false),
        orderBy("createdAt", "desc"),
      ],
      [userSearchState]
    )
  );

  return usersDocs?.docs.map(
    (u) => normalizeUser(set("id", u.id)(u.data())) as UserEntity
  ) as UserEntity[];
};

export default useUsers;
