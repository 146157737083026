import UserEntity, { normalizeUser } from "../../types/User";
import { set } from "lodash/fp";
import useFirestoreDocument from "./useFirestoreDocument";

const useUser = (userId: string) => {
  const userDoc = useFirestoreDocument(`users/${userId}`);
  return userDoc
    ? (normalizeUser(
        set("id", userDoc.id)(userDoc.data() as UserEntity)
      ) as UserEntity)
    : undefined;
};

export default useUser;
