import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useForm } from "react-hook-form";
import { Analysis, Result } from "../../types/Analysis";
import { addAnalysis } from "../../api/analysisAPI";
import { set } from "lodash/fp";

const defaultAnalysis = {
  type: "SARS CoV-2 antigen",
  method: "Lumiquick Diagnostics Inc. Usa - Immunochromatographic",
  result: Result.NEGATIVE,
  dateOfIssue: new Date(),
  dateOfSampling: new Date(),
  referenceValues: "Positive/Negative",
  deleted: false
} as Analysis;

interface Props {
  userId: string;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#d32f2f",
    },
    marginBottom: "20px",
  },
}));
const AddAnalysisButton: React.FC<Props> = ({ userId }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Analysis>({
    defaultValues: defaultAnalysis,
  });

  const onAddAnalysis = (data: Analysis) => {
    addAnalysis(userId, set("dateOfIssue", new Date())(data));
    setOpen(false);
    reset(defaultAnalysis);
  };
  return (
    <>
      <Box alignSelf="flex-end" pb={2}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Dodaj analize
        </Button>
      </Box>
      <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right">
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <form
            id="analysis-form"
            onSubmit={handleSubmit(onAddAnalysis)}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="type"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.root}
                  label="Tip"
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  style={{ marginBottom: 15 }}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="method"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={classes.root}
                  label="Metoda"
                  error={!!errors.method}
                  helperText={errors.method?.message}
                  style={{ marginBottom: 15 }}
                />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="input-result-label">Rezultat</InputLabel>
              <Controller
                name="result"
                control={control}
                rules={{
                  required: { value: true, message: "Ovo polje je obavezno." },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Rezultat"
                    style={{ marginBottom: 15 }}
                  >
                    {Object.keys(Result).map((res) => (
                      <MenuItem value={res} key={res}>
                        {res}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  className={classes.root}
                  label="Cena"
                  style={{ marginBottom: 15 }}
                />
              )}
            />
          </form>
          <Button
            form="analysis-form"
            color="success"
            variant="contained"
            type="submit"
          >
            Dodaj analizu
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default AddAnalysisButton;
